@import './../../../index.scss';

.tools {
  margin-top: 15px;
  border-top: 1px #ebebeb solid;

  .title {
    text-align: center;
    font-size: $desktopSizeTitle;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: $mobileSizeTitle;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    .item {
      display: flex;
      flex-direction: column;
      margin: 0px 20px 20px 20px;
      border: 1px #ccc solid;
      border-radius: 10px;
      width: 25%;

      @media (max-width: 767px) {
        width: 80%;
      }

      .itemTitle {
        border-bottom: 1px #ccc solid;
        padding-bottom: 5px;
        font-size: 20px;
        padding: 10px;
        text-align: center;
        color: #000;
        background-color: #d4e9c2;
        border-radius: 10px 10px 0px 0px;
        font-weight: 500;
      }

      .itemDescription {
        font-size: $desktopSizeText;
        padding: 10px;
        line-height: 25px;

        @media (max-width: 767px) {
          font-size: $mobileSizeText;
        }
      }

      .itemInput {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 10px 10px;
        color: #000;
        height: 40px;
        border-top: 1px #ccc solid;
        cursor: pointer;
        margin-top: auto;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      
      .itemInput:hover {
        background-color: #d4e9c2;
        transition: 0.5s;
      }
    }

    .item:last-child {
      margin: 0px 20px 20px 20px;
    }
  }
}