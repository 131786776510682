@import '../../index.scss';

.header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
  border-bottom: 1px #f2f2f2 solid;
  border-top: 1px #f2f2f2 solid;
  z-index: 2;

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .logoBlock {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 767px) {
        align-items: center;
      }

      .logo {
        //width: 500px;
        //height: 80px;

        width: 439px;
        height: 70px;

        @media (max-width: 767px) {
          width: 300px;
          height: 48px;
        }
      }

      .supTitle {
        font-weight: 500;
        color: #808080;
        font-size: sizeSupText;
        margin-top: 5px;

        @media (max-width: 767px) {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .navBlock {
      display: flex;

      .navItem {
        font-size: 18px;
        margin-right: 20px;
        cursor: pointer;
        transition: 0.5s;

        @media (max-width: 767px) {
          font-size: 12px;
          text-align: center;
          margin-top: 10px;
        }
      }
      .navItem:hover {
        color: #44944A;
        transition: 0.5s;
      }
    }
  }
}