@import './../../../index.scss';

.partners {
  margin-top: 15px;
  border-top: 1px #ebebeb solid;
  
  .title {
    text-align: center;
    font-size: $desktopSizeTitle;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: $mobileSizeTitle;
    }
  }
  
  .carousel {
    display: flex;
  
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      text-align: center;
      pointer-events: none;
      user-select: none;
  
      .text {
        font-size: 12px;
      }
    }
  }
}