.bigpage {
  margin-top: 15px;

  @media (max-width: 767px) {
    margin-top: 0px !important;
  }

  .container {
    display: flex;
    justify-content: center;

    .image {
      height: 600px;
      border-radius: 60px;
      background-position: center;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.03);

      @media (max-width: 767px) {
        height: 150px;
        border-radius: 20px;
      }
    }
  }
}