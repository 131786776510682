html, body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
}
li {
  list-style: none;
}


// desktop
$desktopSizeTitle: 26px;
$desktopSizeText: 16px;
$desktopSizeSupText: 16px;

// mobile
$mobileSizeTitle: 24px;
$mobileSizeText: 14px;