@import './../../../index.scss';

.founders {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border-top: 1px #ebebeb solid;

  @media (max-width: 767px) {
    margin-top: 0;
  }

  .title {
    text-align: center;
    font-size: $desktopSizeTitle;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      font-size: $mobileSizeTitle;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    border: 1px #ebebeb solid;
    width: 90%;
    border-radius: 10px;
    padding: 40px 0px 40px 0px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.03);

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 0px 0px 40px 0px;
    }

    .imageBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image {
        height: 559px;
        width: 377px;
        border-radius: 10px;

        @media (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }

      .link {
        font-size: $desktopSizeText;
        margin-top: 25px;
        cursor: pointer;
        color: #44944A;
        font-weight: 500;
        text-decoration: underline;

        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }

    .textBlock {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: 767px) {
        align-content: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
      }

      .name {
        font-size: 20px;
        border-bottom: 1px #ebebeb solid;
        padding-bottom: 5px;

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .text {
        font-size: $desktopSizeText;
        margin-top: 10px;
        line-height: 25px;

        @media (max-width: 767px) {
          font-size: $mobileSizeText;
        }
      }
    }
  }
}