@import './../../../index.scss';

.about {
  margin-top: 15px;
  border-top: 1px #ebebeb solid;

  .title {
    text-align: center;
    font-size: $desktopSizeTitle;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: $mobileSizeTitle;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-size: $desktopSizeText;
      text-align: justify;
      max-width: 1300px;
      color: #000;
      margin-bottom: 5px;
      margin-left: 20px;
      margin-right: 20px;
      line-height: 25px;

      @media (max-width: 767px) {
        font-size: $mobileSizeText;
        margin-left: 20px;
        margin-right: 20px;
      }

      @media (max-width: 1024px) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}