@import './../../../index.scss';

.news {
  margin-top: 15px;
  border-top: 1px #ebebeb solid;

  .title {
    text-align: center;
    font-size: $desktopSizeTitle;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: $mobileSizeTitle;
    }
  }
  
  .container {
    max-width: 1300px;
    margin: 0 auto;
  }
}