@import './../../index.scss';

.footer {
  margin-top: 25px;
  //background-color: #f2f2f2;
  border-top: 1px #f2f2f2 solid;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.13);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    .mainBlock {
      display: flex;
      justify-content: space-around;
      width: 70%;
      margin-top: 10px;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        width: 95%;
      }

      .contacts {
        display: flex;
        flex-direction: column;
        margin: 5px;

        .title {
          font-weight: 600;
          font-size: 20px;
          border-bottom: 1px #ccc solid;
          padding-bottom: 10px;
          text-decoration: none;
          color: #000;
          width: 100%;

          @media (max-width: 767px) {
            font-size: 17px;
          }
        }

        .text {
          font-size: sizeText;
          margin-top: 10px;
          text-decoration: none;
          color: #000;
          font-weight: 400;
          line-height: 20px;

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }

    .bottomBlock {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 5px;
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}