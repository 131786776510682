@import '../../../../index.scss';

.news {
  width: 90%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px 10px 10px 10px;
  transition: 0.5s;

  @media (max-width: 767px) {
    width: 85%;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
      
    .newsImage {
      width: 100% !important;
      border-bottom: 1px #ccc solid;
      border-radius: 10px 10px 0px 0px;
      height: auto;

      @media (max-width: 767px) {
        height: auto;
      }
    }
    
    .newsContent {
      display: flex;
      flex-direction: column;

      .newsTopBlock {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px #f2f2f2 solid;

        .newsControl {
          color: blue;
          margin: 10px 10px 0px 10px;
          cursor: pointer;
        }
    
        .newsInfoDate {
          font-size: $desktopSizeText;
          margin: 10px 10px 0px 10px;
  
          @media (max-width: 767px) {
            font-size: $mobileSizeText;
          }
        }
      }
  
      .newsInfoText {
        font-size: $desktopSizeText;  
        margin: 10px 10px 10px 10px;
        white-space: pre-wrap;
        line-height: 25px;
        overflow: hidden;

        @media (max-width: 767px) {
          font-size: $mobileSizeText;
        }
      }
    }
  }
}

.newsItem:hover {
  border: 1px solid green;
  transition: 0.5s;
  cursor: pointer;
  box-shadow: 0px 14px 30px rgba(0,0,0,0.36);
  transform: translateY(-5px);
}