@import './../../../index.scss';

.detailsModal {
  position: fixed;
  top: 0;
  background-color: rgba(52, 58, 64, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 2;

  .detailsModalContent {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 1px #fff solid;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.13);
    overflow-y: auto;

    @media (max-width: 767px) {
      width: 90%;
      height: 90%;
    }

    .detailsModalTitleBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px #ccc solid;
      padding-bottom: 10px;

      .detailsModalTitle {
        text-align: start;
        color: #000;
        font-weight: 600;
        font-size: $desktopSizeTitle;
        margin-top: 10px;
        margin-left: 10px;

        @media (max-width: 767px) {
          font-size: $mobileSizeTitle;
        }
      }

      .detailsModalDart {
        display: flex;
        align-items: center;
        color: #c42525;
        text-decoration: none;
        margin-right: 32px;
        height: 100%;
        font-size: $desktopSizeTitle;
        cursor: pointer;

        @media (max-width: 767px) {
          font-size: $mobileSizeTitle;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
    }

    .detailsModalTextBlock {
      margin: 15px;

      .detailsModalText {
        text-align: start;
        color: #000;
        font-size: 16px;
      }
    }

    .detailsModalPointsBlock {
      margin: 15px;
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
}